import styled from "styled-components";
import Modal from "react-modal";
import ActionButton from "../Button";

export const ModalStyled = styled(Modal)`
  height: 50vh;
  min-height: 50vh;
  min-width: 60vw;
  width: 60vw;
`;

export const ContentContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: 0 10px 20px rgba(48, 48, 45, 0.5);

  padding: 2.5rem;
  position: relative;

  opacity: 0;
  transform: translateY(40px);
  transition: transform 0.3s ease-in-out, opacity 0.4s ease-in-out;

  max-width: 30vw;

  z-index: 999;

  &.ReactModal__Content--after-open {
    opacity: 1;
    transform: translateY(0);
  }

  &.ReactModal__Content--before-close {
    opacity: 0;
    transform: translateY(40px);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Content = styled.div``;

export const Close = styled.p`
  cursor: pointer;
  font-weight: bold;
  right: 8px;
  padding: 8px;
  position: absolute;
  top: 8px;
`;

export const Button = styled(ActionButton)`
  margin: 15px 0 0 0;
`;

export const OverlayContainer = styled.div`
  background-color: rgba(0, 0, 0, 0) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;

  z-index: 99;

  &.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }

  &.ReactModal__Overlay--before-close {
    background-color: rgba(0, 0, 0, 0) !important;
  }
`;
