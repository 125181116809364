import { DefaultTheme } from "styled-components";

const defaultTheme: DefaultTheme = {
  colors: {
    alabaster: "#f8f8f8",
    black: "#000000",
    iron: "#d2d4d6",
    osloGray: "#959696",
    white: "#ffffff",
    wildSand: "#f5f5f5",
    silverSand: "#C0C2C4",
    red: "#DF2935",
    surfieGreen: "#0f7d7c",
  },
};

export default defaultTheme;
