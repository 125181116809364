import api from "~/services/api";
import history from "../history";

const Auth = {
  login: async (login: string, password: string) => {
    const response = await api.post<User>("/login", { login, password });

    const { data } = response;

    if (data.token) {
      localStorage.setItem("auth", JSON.stringify(data));
    }

    return response.data;
  },
  logout: () => {
    localStorage.removeItem("auth");
    history.push("/");
  },
  getCurrentUser: () => {
    const auth = localStorage.getItem("auth");
    if (auth) {
      return JSON.parse(auth) as User;
    }

    return null;
  },
};

export default Auth;
