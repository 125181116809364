import styled from "styled-components";

export const TextAreaStyled = styled.textarea.attrs(() => ({
  rows: 15,
}))`
  border: 1px solid ${({ theme: { colors } }) => colors.iron};
  border-radius: 4px;
  font-size: 15px;
  margin-top: 5px;
  padding: 0 5px;
  resize: none;
  width: 100%;
  transition: border-color 500ms;

  &:focus {
    border-color: ${({ theme: { colors } }) => colors.osloGray};
  }
`;
