import styled, { css } from "styled-components";

export const Container = styled.div`
  align-items: center;
  display: flex;
`;

interface StepProps {
  isSelected: boolean;
}

export const Step = styled.div<StepProps>`
  border: 1px solid var(--primary);
  border-radius: 6px;
  height: 12px;
  margin: 0 2px;
  width: 12px;
  transition: background-color 500ms;

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: var(--primary);
    `}
`;
