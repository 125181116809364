import Auth from "./Auth";

export const getBearerToken = () => {
  const user = Auth.getCurrentUser();

  if (user?.token) {
    // for Node.js Express back-end
    return { Authorization: `Bearer ${user.token}` };
  }

  return {};
};
