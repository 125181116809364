import React, { useCallback, useEffect, useMemo, useState } from "react";
import ActionButton from "~/components/ActionButton";
import Input from "~/components/Input";
import Table from "~/components/Table";
import { partsType } from "~/constants";
import usePart from "~/hooks/usePart";
import { Container, Content, Header } from "./styles";
import bin from "~/assets/icons/bin.svg";
import pencil from "~/assets/icons/pencil-white.svg";
import Button from "~/components/Button";

interface StepProps {
  setInQuery: (inQuery: boolean) => void;
}

const Step: React.VFC<StepProps> = ({ setInQuery }) => {
  const [title, setTitle] = useState<string>("");

  const {
    part,
    selected,
    selectedStep,
    addStep,
    removeStepContent,
    setSelectedStepContent,
  } = usePart();

  const COLUMNS: Column<StepContent>[] = useMemo(
    () => [
      { header: "Título", value: "title" },
      {
        header: "Tipo",
        value: (value) => {
          return (
            partsType.find(({ type }) => value.type === type)?.description ??
            value.type
          );
        },
      },
      {
        header: "",
        isComponent: true,
        value: (_, index) => [
          <ActionButton
            color="var(--primary)"
            icon={pencil}
            onClick={() => {
              setSelectedStepContent(index);
              setInQuery(false);
            }}
          />,
          <ActionButton
            color="var(--red)"
            icon={bin}
            onClick={() => removeStepContent(index)}
          />,
        ],
      },
    ],
    [setInQuery, removeStepContent, setSelectedStepContent]
  );

  const stepContent = useMemo(() => {
    if (selected !== undefined && selectedStep !== undefined) {
      return part.operationList[selected].steps[selectedStep].stepContent;
    }

    return [];
  }, [part, selected, selectedStep]);

  const createStep = useCallback(() => {
    addStep(title);

    setInQuery(false);
  }, [title, addStep, setInQuery]);

  useEffect(() => {
    if (selected !== undefined && selectedStep !== undefined) {
      setTitle(part.operationList[selected].steps[selectedStep].title);
    }
  }, [part.operationList, selected, selectedStep]);

  return (
    <Container>
      <Header>
        <Input
          placeholder="Título"
          value={title}
          onChange={({ target }) => setTitle(target.value)}
        />
        <Button onClick={createStep}>Adicionar</Button>
      </Header>

      <Content>
        <Table
          columns={COLUMNS}
          data={stepContent}
          onGetKey={({ title }) => title}
        />
      </Content>
    </Container>
  );
};

export default Step;
