import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  root: {
    display: "flex",
    minWidth: "246px",
    height: "calc(100vh - 70px)",
    backgroundImage: "linear-gradient(var(--primaryGradient))",
  },
  listContainer: {
    padding: "0 30px",
    marginTop: "10vh",
  },
  listItem: {
    fontSize: 18,
    display: "flex",
    marginBottom: 40,
    cursor: "pointer",
    alignItems: "center",
    color: "var(--white)",
  },
  lastItem: {
    fontSize: 17,
    display: "flex",
    marginBottom: 40,
    cursor: "pointer",
    alignItems: "center",
    color: "var(--white)",
    position: "absolute",
    bottom: -20,
    left: 30,
  },
  selectedListItem: {
    fontSize: 18,
    display: "flex",
    fontWeight: 600,
    marginBottom: 40,
    cursor: "pointer",
    alignItems: "center",
    color: "var(--white)",
  },
  icon: {
    marginRight: 10,
  },
  logoutIcon: {
    marginRight: 10,
    width: 20,
  },
});

export default useStyles;
