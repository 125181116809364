import styled from "styled-components";

export const Label = styled.p`
  margin-right: 5px;
  font-weight: bold;
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`;
