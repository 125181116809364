import { Router } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";

// Services
import { ThemeProvider } from "styled-components";
import history from "./services/history";

// Routes
import Routes from "./routes";

// Styles
import GlobalStyle from "./styles/global";
import theme from "./theme";

// Context
import ApplicationContext from "./hooks";

const App = () => (
  <ThemeProvider theme={theme}>
    <ApplicationContext>
      <Router history={history}>
        <Routes />
        <GlobalStyle />
        <ToastContainer autoClose={3000} draggable transition={Slide} />
      </Router>
    </ApplicationContext>
  </ThemeProvider>
);

export default App;
