import { useCallback, useEffect, useMemo, useState } from "react";
import { useTheme } from "styled-components";
import Table from "~/components/Table";
import MessageModal from "~/components/Modal/Message";
import { formatDate } from "~/adapter/dates";
import ActionButton from "../../components/ActionButton";
import bin from "~/assets/icons/bin.svg";
import pencil from "~/assets/icons/pencil-white.svg";
import { Container, LoadingContainer } from "./styles";
import SearchInput from "~/components/SearchInput";
import usePart from "~/hooks/usePart";
import useToast from "~/hooks/useToast";
import Loading from "~/components/Loading";
import history from "~/services/history";

const Query: React.VFC = () => {
  const [data, setData] = useState<Part[]>([]);
  const [filteredData, setFilteredData] = useState<Part[] | undefined>(
    undefined
  );
  const [reload, setReload] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<Part | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { load, remove, setPart } = usePart();
  const { show } = useToast();

  const { colors } = useTheme();

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await load();

      setData(response.parts);
    } finally {
      setLoading(false);
    }
  }, [load]);

  useEffect(() => {
    fetchData();
  }, [reload, fetchData]);

  const handleCloseDeleteModal = useCallback(() => {
    setSelectedRow(null);
    setOpenDeleteModal(false);
  }, []);

  const handleDeletePart = useCallback(async () => {
    if (!selectedRow) {
      return;
    }

    try {
      setLoading(true);
      await remove(selectedRow.SKU);

      setReload(!reload);

      handleCloseDeleteModal();

      show({
        type: "success",
        message: "Peça deletada com sucesso!",
      });
    } catch {
      show({
        type: "error",
        message:
          "Ocorreu um problema e não foi possível apagar o SKU, tente novamente.",
      });
    } finally {
      setLoading(false);
    }
  }, [reload, selectedRow, remove, handleCloseDeleteModal, show]);

  const handleEdit = useCallback(
    (part: Part) => {
      setPart(part);
      history.push("/cadastro", { editing: true });
    },
    [setPart]
  );

  // Table headers and data identifiers
  const COLUMNS: Column<Part>[] = useMemo(
    () => [
      { header: "SKU", value: "SKU" },
      { header: "Nome", value: "desc" },
      {
        header: "Data de Criação",
        value: ({ created_at }) => formatDate(created_at),
      },
      {
        header: "",
        isComponent: true,
        value: (row) => [
          <ActionButton
            color="var(--primary)"
            icon={pencil}
            onClick={() => handleEdit(row)}
          />,
          <ActionButton
            color="var(--red)"
            icon={bin}
            onClick={() => {
              setSelectedRow(row);
              setOpenDeleteModal(true);
            }}
          />,
        ],
      },
    ],
    [handleEdit]
  );

  return (
    <Container>
      {loading ? (
        <LoadingContainer>
          <Loading color={colors.surfieGreen} />
        </LoadingContainer>
      ) : (
        <>
          <SearchInput data={data} setFilteredData={setFilteredData} />
          <Table
            darkMode
            columns={COLUMNS}
            data={filteredData || data}
            onGetKey={({ SKU }) => SKU}
          />
        </>
      )}

      {/* Delete Confirmation Modal */}
      <MessageModal
        loading={loading}
        isOpen={openDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleCancel={handleCloseDeleteModal}
        handleConfirm={handleDeletePart}
      >
        <p>Deseja realmente deletar a peça:</p>
        <p>
          {selectedRow?.SKU} - {selectedRow?.desc}
        </p>
      </MessageModal>
    </Container>
  );
};

export default Query;
