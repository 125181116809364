import React from "react";
import { Container, Step } from "./styles";

interface StepsProps {
  quantity: number;
  current: number;
}

const Steps: React.VFC<StepsProps> = ({ quantity, current }) => (
  <Container>
    {Array(quantity)
      .fill(0)
      .map((_, index) => (
        <Step isSelected={current === index} />
      ))}
  </Container>
);

export default Steps;
