import styled from "styled-components";

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  flex-direction: row;

  * {
    margin-right: 5px;
  }
`;

export const TextAreaStyled = styled.textarea.attrs(() => ({
  rows: 15,
}))`
  border: 1px solid ${({ theme: { colors } }) => colors.iron};
  border-radius: 4px;
  font-size: 15px;
  margin: 15px 15px 0 0;
  padding: 5px;
  resize: none;
  width: 100%;
  transition: border-color 500ms;

  &:focus {
    border-color: ${({ theme: { colors } }) => colors.osloGray};
  }
`;

export const Content = styled.div`
  display: flex;
  margin-top: 15px;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Separator = styled.div`
  background-color: ${({ theme }) => theme.colors.iron};
  min-height: 100%;
  margin: 45px 35px 35px 25px;
  width: 1px;
`;

export const GridContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  position: relative;
  margin-top: 5px;
`;

export const GridContent = styled.div`
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
`;
