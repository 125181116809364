import { useCallback, useEffect, useRef, useState } from "react";
import Button from "~/components/Button";
import Input from "~/components/Input";
import usePart from "~/hooks/usePart";
import useToast from "~/hooks/useToast";
import { thereAreOnlyNumbers } from "~/utils";
import ListItem from "../ListItem";
import {
  Content,
  Form,
  GridContainer,
  GridContent,
  Header,
  Row,
  Separator,
  TextAreaStyled,
} from "./styles";

const Measurement = () => {
  const [title, setTitle] = useState<string>("");
  const [imageURL, setImageURL] = useState<string>("");
  const [sample, setSample] = useState<string>("");
  const [size, setSize] = useState<Measure>({
    min: "",
    max: "",
  });
  const [measure, setMeasure] = useState<Measure[]>([]);
  const [editing, setEditing] = useState<number>();

  const [editableValue, setEditableValue] = useState<Measure>({} as Measure);

  const {
    saving,
    stepContent,
    setError,
    setSaving,
    addStepContent,
    setSelectedStepContent,
  } = usePart();
  const { show } = useToast();

  const inputMinRef = useRef<HTMLInputElement>(null);
  const inputMaxRef = useRef<HTMLInputElement>(null);

  const addMeasure = useCallback(() => {
    setMeasure((v) => {
      const { min, max } = size;

      const list = [...v, { min, max }];

      return list;
    });
    setSize({ min: "", max: "" });
    inputMinRef.current?.focus();
  }, [inputMinRef, size]);

  const handleEditClick = useCallback(
    (index: number) => {
      if (editing !== undefined) {
        const list = [...measure];
        list[index].min = editableValue.min;
        list[index].max = editableValue.max;

        setMeasure(list);
        setEditing(undefined);

        return;
      }

      const { min, max } = measure[index];

      setEditableValue({
        min,
        max,
      });

      setEditing(index);
    },
    [editing, measure, editableValue.min, editableValue.max]
  );

  const handleDeleteClick = useCallback((index: number) => {
    setMeasure((v) => {
      const list = [...v];

      list.splice(index, 1);

      return list;
    });
  }, []);

  useEffect(() => {
    if (saving) {
      const sampleList = sample.split("\n").filter((v) => v);

      setError(false);

      if (!!title && sampleList.length > 0 && measure.length > 0) {
        addStepContent({
          type: "measurement",
          content: {
            sample: sampleList,
            measure,
          },
          title,
          imageURL,
        });
      } else {
        show({
          type: "error",
          message: "Todas informações devem ser preenchidas.",
        });

        setError(true);
      }

      setSelectedStepContent(undefined);
      setSaving(false);
    }
  }, [
    title,
    sample,
    saving,
    measure,
    imageURL,
    show,
    setError,
    setSaving,
    addStepContent,
    setSelectedStepContent,
  ]);

  useEffect(() => {
    if (stepContent && stepContent.type === "measurement") {
      const { content } = stepContent;

      setTitle(stepContent.title);
      setImageURL(stepContent.imageURL || "");
      setSample(content.sample.reduce((prev, curr) => `${prev}\n${curr}`));
      setMeasure(content.measure);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header>
        <Input
          placeholder="Título"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <Input
          placeholder="URL da imagem"
          value={imageURL}
          onChange={(e) => setImageURL(e.target.value)}
          style={{ width: 300 }}
        />
      </Header>

      <Content>
        <TextAreaStyled
          placeholder="Amostras"
          value={sample}
          onChange={(e) => setSample(e.target.value)}
        />

        <Separator />

        <Form>
          <Row>
            <Input
              ref={inputMinRef}
              placeholder="Mínimo"
              value={size.min}
              onChange={(e) => {
                if (!thereAreOnlyNumbers(e.target.value)) {
                  return;
                }

                setSize((o) => ({ ...o, min: e.target.value }));
              }}
              style={{ marginRight: 15 }}
            />

            <Input
              ref={inputMaxRef}
              placeholder="Máximo"
              value={size.max}
              onChange={(e) => {
                if (!thereAreOnlyNumbers(e.target.value)) {
                  return;
                }

                setSize((o) => ({ ...o, max: e.target.value }));
              }}
            />

            <Button
              size="sm"
              maxWidth="105px"
              disabled={!size.min || !size.max}
              style={{ marginRight: 0, maxHeight: "30px" }}
              onClick={addMeasure}
            >
              Adicionar
            </Button>
          </Row>

          <GridContainer>
            <GridContent>
              {measure.map((item, index) => (
                <ListItem
                  onDelete={() => handleDeleteClick(index)}
                  onEdit={() => handleEditClick(index)}
                >
                  {editing !== undefined && editing === index ? (
                    <>
                      <input
                        value={editableValue.min}
                        onChange={(e) => {
                          if (!thereAreOnlyNumbers(e.target.value)) {
                            return;
                          }

                          setEditableValue((v) => ({
                            ...v,
                            min: e.target.value,
                          }));
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") handleEditClick(index);
                        }}
                      />

                      <input
                        value={editableValue.max}
                        onChange={(e) => {
                          if (!thereAreOnlyNumbers(e.target.value)) {
                            return;
                          }

                          setEditableValue((v) => ({
                            ...v,
                            max: e.target.value,
                          }));
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") handleEditClick(index);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <p>{`Cota ${String.fromCharCode(index + 65)}`}</p>
                      <p>{item.min}</p>
                      <p>{item.max}</p>
                    </>
                  )}
                </ListItem>
              ))}
            </GridContent>
          </GridContainer>
        </Form>
      </Content>
    </>
  );
};

export default Measurement;
