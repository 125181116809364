import { Ball, Container } from "./styles";

interface LoadingProps {
  color?: string;
}

const Loading = ({ color }: LoadingProps) => {
  //
  return (
    <Container>
      <Ball delay="0s" style={{ background: color }} />
      <Ball delay="1s" style={{ background: color }} />
      <Ball delay="2s" style={{ background: color }} />
    </Container>
  );
};

export default Loading;
