import React, { useEffect, useState } from "react";
import search_icon from "~/assets/icons/search.svg";
import { Container, Input } from "./styles";

interface SearchInputProps {
  data: Part[];
  setFilteredData: (parts?: Part[]) => void;
}

const SearchInput: React.VFC<SearchInputProps> = ({
  data,
  setFilteredData,
}) => {
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (search.length > 0) {
      const filtered = data.filter(
        (row) => row.SKU.includes(search) || row.desc.includes(search)
      );

      setFilteredData(filtered);
      return;
    }

    setFilteredData(undefined);
  }, [data, search, setFilteredData]);

  return (
    <Container>
      <Input
        value={search || ""}
        onChange={(e) => setSearch(e.target.value)}
        maxLength={15}
      />
      <img src={search_icon} alt="" />
    </Container>
  );
};

export default SearchInput;
