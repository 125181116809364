import ReactDOM from "react-dom";
import { config } from "dotenv";
import App from "./App";
import { initializeFirebase } from "./services/firebase";

config();

initializeFirebase();

ReactDOM.render(<App />, document.getElementById("root"));
