import React, { useCallback } from "react";
import Select from "~/components/Select";
import { partsType } from "~/constants";
import { Container, Label } from "./styles";

interface TypeProps {
  selectedType: string;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
}

const Type: React.VFC<TypeProps> = ({ selectedType, onChange }) => {
  const RenderCombo = useCallback(
    () => (
      <Select onChange={onChange}>
        {partsType.map(({ type, description }) => (
          <option selected={selectedType === type} value={type}>
            {description}
          </option>
        ))}
      </Select>
    ),
    [onChange, selectedType]
  );

  return (
    <Container>
      <Label>Tipo:</Label>
      <RenderCombo />
    </Container>
  );
};

export default Type;
