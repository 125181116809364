import { useCallback, useEffect, useState } from "react";
import {
  Container,
  ContentContainer,
  Close,
  Footer,
  ModalStyled,
  Button,
  OverlayContainer,
} from "./styles";
import TypeStep from "./components/Type";
import Steps from "./components/Steps";
import Content from "./components/Content";
import Step from "./components/Step";
import usePart from "~/hooks/usePart";

const steps = 2;

interface StepModalProps {
  visible: boolean;
  onClose: () => void;
}

const StepModal: React.VFC<StepModalProps> = ({ visible, onClose }) => {
  const [inQuery, setInQuery] = useState(true);
  const [step, setStep] = useState(0);
  const [type, setType] = useState<StepType>("text");

  const { error, saving, stepContent, setSaving, setSelectedStepContent } =
    usePart();

  const RenderStep = useCallback(() => {
    if (step === 0) {
      return (
        <TypeStep
          selectedType={type}
          // TODO: Maybe there's a better way to do this
          onChange={(e) => setType(e.target.value as StepType)}
        />
      );
    }

    if (step === 1) {
      return <Content type={type} />;
    }

    return null;
  }, [step, type]);

  const handleBack = useCallback(() => {
    if (step === 0) {
      setInQuery(true);
      return;
    }

    setStep((v) => v - 1);
  }, [step]);

  const handleNext = useCallback(() => {
    if (step < steps - 1) {
      setStep(step + 1);
      return;
    }

    setSaving(true);
  }, [step, setSaving]);

  useEffect(() => {
    if (visible) {
      setInQuery(true);
      setStep(0);
    }
  }, [visible]);

  useEffect(() => {
    if (!saving && !error) {
      setInQuery(true);
      setStep(0);
    }
  }, [error, saving]);

  useEffect(() => {
    if (!inQuery) {
      if (stepContent) {
        setType(stepContent.type);
      } else {
        setType("text");
      }
    } else {
      setSelectedStepContent(undefined);
    }
  }, [inQuery, stepContent, setSelectedStepContent]);

  return (
    <ModalStyled
      isOpen={visible}
      onRequestClose={onClose}
      style={{ overlay: { backgroundColor: "rgba(64, 64, 52, 0.43)" } }}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      closeTimeoutMS={380}
      overlayElement={(props, children) => (
        <OverlayContainer {...props}>{children}</OverlayContainer>
      )}
      contentElement={(props, children) => (
        <ContentContainer {...props}>{children}</ContentContainer>
      )}
    >
      <Container>
        <Close onClick={onClose}>X</Close>
        {inQuery ? (
          <Step setInQuery={setInQuery} />
        ) : (
          <>
            <RenderStep />

            <Footer>
              <Button onClick={handleBack}>Anterior</Button>

              <Steps current={step} quantity={steps} />

              <Button onClick={handleNext}>
                {step === steps - 1 ? "Salvar" : "Próximo"}
              </Button>
            </Footer>
          </>
        )}
      </Container>
    </ModalStyled>
  );
};

export default StepModal;
