/* eslint-disable react/prop-types */
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import api from "~/services/api";
import { getBearerToken } from "~/services/utils";

interface PartProps {
  part: Part;
  selected: number | undefined;
  selectedStep: number | undefined;
  selectedStepContent: number | undefined;
  saving: boolean;
  error: boolean;
  stepContent: StepContent | null;
  load: () => Promise<Parts>;
  save: () => Promise<void>;
  edit: (SKU: string, desc: string) => void;
  clear: () => void;
  change: () => Promise<void>;
  remove: (SKU: string) => Promise<void>;
  create: (SKU: string, desc: string) => void;
  setPart: (part: Part) => void;
  changePart: (data: ChangePartType) => void;
  addOperation: () => void;
  removeOperation: (index: number) => void;
  changeOperation: (index: number, operationTitle: string) => void;
  changeOperationPosition: (index: number, order: "up" | "down") => void;
  addStep: (title: string) => void;
  addStepContent: (content: StepContent) => void;
  removeStep: (index: number) => void;
  removeStepContent: (index: number) => void;
  setSelected: (selected: number | undefined) => void;
  setSaving(saving: boolean): void;
  setError(error: boolean): void;
  setSelectedStep: (selected: number | undefined) => void;
  setSelectedStepContent: (selected: number | undefined) => void;
}

const PartContext = createContext({} as PartProps);

export const PartProvider: React.FC = ({ children }) => {
  const [part, setPart] = useState<Part>({
    SKU: "",
    desc: "",
    operationList: [],
    created_at: new Date().toString(),
  });
  const [selected, setSelected] = useState<number>();
  const [selectedStep, setSelectedStep] = useState<number>();
  const [selectedStepContent, setSelectedStepContent] = useState<number>();

  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  // const selectedStep = useMemo(() => {
  //   if (selected !== undefined) {
  //     const index = part.operationList[selected].steps.length - 1;

  //     return index >= 0 ? index : undefined;
  //   }

  //   return undefined;
  // }, [part.operationList, selected]);

  const stepContent = useMemo(() => {
    if (
      selected === undefined ||
      selectedStep === undefined ||
      selectedStepContent === undefined
    ) {
      return null;
    }

    return part.operationList[selected].steps[selectedStep].stepContent[
      selectedStepContent
    ];
  }, [part.operationList, selected, selectedStep, selectedStepContent]);

  const create = useCallback((SKU, desc) => {
    setPart({
      SKU,
      desc,
      operationList: [],
      created_at: new Date().toString(),
    });
  }, []);

  const edit = useCallback((SKU, desc) => {
    setPart((v) => ({
      ...v,
      SKU,
      desc,
    }));
  }, []);

  const changePart = useCallback(({ SKU, desc }: ChangePartType) => {
    setPart((v) => {
      if (SKU === "" || SKU) {
        v.SKU = SKU;
      }

      if (desc === "" || desc) {
        v.desc = desc;
      }

      return { ...v };
    });
  }, []);

  const addOperation = useCallback(() => {
    setPart((v) => ({
      ...v,
      operationList: [
        ...v.operationList,
        {
          operationTitle: String(v.operationList.length),
          status: "TODO",
          steps: [],
        },
      ],
    }));
  }, []);

  const removeOperation = useCallback((index: number) => {
    setPart((v) => {
      const list = [...v.operationList];

      list.splice(index, 1);

      setSelected(undefined);

      return {
        ...v,
        operationList: list,
      };
    });
  }, []);

  const changeOperation = useCallback(
    (index: number, operationTitle: string) => {
      setPart((v) => {
        const list = [...v.operationList];

        list[index].operationTitle = operationTitle;

        return {
          ...v,
          operationList: list,
        };
      });
    },
    []
  );

  const changeOperationPosition = useCallback(
    (index: number, order: "up" | "down") => {
      setPart((v) => {
        const item = v.operationList[index];

        if (order === "up") {
          v.operationList[index] = v.operationList[index - 1];
          v.operationList[index - 1] = item;
        } else {
          v.operationList[index] = v.operationList[index + 1];
          v.operationList[index + 1] = item;
        }

        return v;
      });
    },
    []
  );

  const addStep = useCallback(
    (title) => {
      if (selected === undefined) {
        return;
      }

      setPart((v) => {
        return {
          ...v,
          operationList: v.operationList.map((item, index) => {
            if (selectedStep === undefined && index === selected) {
              setSelectedStep(v.operationList[selected].steps.length);

              return {
                ...item,
                steps: [
                  ...item.steps,
                  {
                    title,
                    stepContent: [],
                  },
                ],
              };
            }

            return {
              ...item,
              steps: item.steps.map((step, index) => {
                if (selectedStep === index) {
                  return {
                    ...step,
                    title,
                  };
                }

                return step;
              }),
            };
          }),
        };
      });
    },
    [selected, selectedStep]
  );

  const removeStep = useCallback(
    (index: number) => {
      if (selected === undefined) {
        return;
      }

      setPart((v) => {
        v.operationList[selected].steps.splice(index, 1);

        return {
          ...v,
        };
      });
    },
    [selected]
  );

  const addStepContent = useCallback(
    ({ title, type, content, imageURL }) => {
      if (selected === undefined || selectedStep === undefined) {
        return;
      }

      setPart((v) => {
        return {
          ...v,
          operationList: v.operationList.map((item, index) => {
            if (index === selected) {
              return {
                ...item,
                steps: item.steps.map((step, index) => {
                  if (index === selectedStep) {
                    let stepContentList: StepContent[] = [];

                    if (selectedStepContent === undefined) {
                      stepContentList = [
                        ...step.stepContent,
                        {
                          title,
                          type,
                          content,
                          imageURL,
                        },
                      ];
                    } else {
                      stepContentList = step.stepContent.map(
                        (stepContent, index) => {
                          if (index === selectedStepContent) {
                            return {
                              title,
                              type,
                              content,
                              imageURL,
                            };
                          }

                          return stepContent;
                        }
                      );
                    }

                    return {
                      ...step,
                      stepContent: stepContentList,
                    };
                  }

                  return step;
                }),
              };
            }

            return item;
          }),
        };
      });
    },
    [selected, selectedStep, selectedStepContent]
  );

  const removeStepContent = useCallback(
    (index: number) => {
      if (selected === undefined || selectedStep === undefined) {
        return;
      }

      setPart((v) => {
        v.operationList[selected].steps[selectedStep].stepContent.splice(
          index,
          1
        );

        return {
          ...v,
        };
      });
    },
    [selected, selectedStep]
  );

  const save = useCallback(async () => {
    const auth = JSON.parse(localStorage.getItem("auth") ?? "");

    if (auth) {
      await api.post("/parts", part, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      setPart({
        SKU: "",
        desc: "",
        operationList: [],
        created_at: new Date().toString(),
      });

      return;
    }

    throw new Error("Não foi possível obter sua autenticação");
  }, [part]);

  const change = useCallback(async () => {
    const auth = JSON.parse(localStorage.getItem("auth") ?? "");

    if (auth) {
      await api.put(`/parts/${part.SKU}`, part, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      setPart({
        SKU: "",
        desc: "",
        operationList: [],
        created_at: new Date().toString(),
      });

      return;
    }

    throw new Error("Não foi possível obter sua autenticação");
  }, [part]);

  const load = useCallback(async () => {
    const { data } = await api.get<Parts>("/parts", {
      headers: getBearerToken(),
    });

    return data;
  }, []);

  const remove = useCallback(async (SKU: string) => {
    await api.delete<{ deletedPart: Part }>(`/parts/${SKU}`, {
      headers: getBearerToken(),
    });
  }, []);

  const clear = useCallback(() => {
    setPart({
      SKU: "",
      desc: "",
      operationList: [],
      created_at: new Date().toString(),
    });
  }, []);

  return (
    <PartContext.Provider
      value={{
        part,
        error,
        saving,
        selected,
        stepContent,
        selectedStep,
        selectedStepContent,
        load,
        save,
        edit,
        clear,
        change,
        create,
        remove,
        addStep,
        setPart,
        setError,
        setSaving,
        removeStep,
        changePart,
        setSelected,
        addOperation,
        addStepContent,
        removeOperation,
        changeOperation,
        setSelectedStep,
        removeStepContent,
        setSelectedStepContent,
        changeOperationPosition,
      }}
    >
      {children}
    </PartContext.Provider>
  );
};

const usePart = (): PartProps => {
  const context = useContext(PartContext);

  if (context) {
    return context;
  }

  throw new Error("Operation provider must be defined.");
};

export default usePart;
