import { useEffect, useState } from "react";
import Input from "~/components/Input";
import usePart from "~/hooks/usePart";
import useToast from "~/hooks/useToast";
import { TextAreaStyled } from "./styles";

const Text = () => {
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const {
    saving,
    stepContent,
    setError,
    setSaving,
    addStepContent,
    setSelectedStepContent,
  } = usePart();
  const { show } = useToast();

  useEffect(() => {
    if (saving) {
      const content = description.split("\n");
      setError(false);

      if (title && content.filter((v) => !!v).length > 0) {
        addStepContent({
          type: "text",
          content,
          title,
        });
      } else {
        show({
          type: "error",
          message: "Todas informações devem ser preenchidas.",
        });

        setError(true);
      }

      setSelectedStepContent(undefined);
      setSaving(false);
    }
  }, [
    description,
    saving,
    title,
    show,
    setError,
    setSaving,
    addStepContent,
    setSelectedStepContent,
  ]);

  useEffect(() => {
    if (stepContent && stepContent.type === "text") {
      setTitle(stepContent.title);
      setDescription(
        stepContent.content.reduce((prev, curr) => `${prev}\n${curr}`)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Input
        placeholder="Título"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />

      <TextAreaStyled
        placeholder="Descrição"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
    </div>
  );
};

export default Text;
