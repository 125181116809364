import React from "react";
import { Route, Redirect } from "react-router-dom";
import AuthLayout from "~/pages/_layouts/auth";
import DefaultLayout from "~/pages/_layouts/default";
import Auth from "~/services/Auth";

interface RouteWrapperProps {
  component: React.FC;
  path?: string;
  exact?: boolean;
  isPrivate?: boolean;
  context?: React.FC;
}

const RouteWrapper = ({
  component: Component,
  isPrivate,
  ...rest
}: RouteWrapperProps) => {
  const user = Auth.getCurrentUser();
  const signed = user?.token;

  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  if (signed && !isPrivate) {
    return <Redirect to="/consulta" />;
  }

  const Layout = signed ? DefaultLayout : AuthLayout;

  return (
    <Route
      {...rest}
      render={() => (
        <Layout>
          <Component />
        </Layout>
      )}
    />
  );
};

export default RouteWrapper;
