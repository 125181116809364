export const partsType: StepTypeMapper[] = [
  {
    type: "text",
    description: "Texto",
  },
  {
    type: "checkbox",
    description: "Checklist",
  },
  {
    type: "measurement",
    description: "Medição",
  },
  {
    type: "tool-table",
    description: "Tabela",
  },
];
