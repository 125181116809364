import { useCallback, useEffect, useState } from "react";
import Input from "~/components/Input";
import useStyles from "./styles";
import usePart from "~/hooks/usePart";
import ListItem from "../ListItem";
import useToast from "~/hooks/useToast";
import Button from "~/components/Button";

const Checklist = () => {
  const styles = useStyles();
  const [title, setTitle] = useState<string>("");
  const [newItem, setNewItem] = useState<string>("");
  const [contentList, setContentList] = useState<string[]>([]);
  const [editing, setEditing] = useState<number>();
  const [editableValue, setEditableValue] = useState<string>("");

  const {
    saving,
    stepContent,
    setError,
    setSaving,
    addStepContent,
    setSelectedStepContent,
  } = usePart();

  const { show } = useToast();

  useEffect(() => {
    if (saving) {
      setError(false);

      if (title && contentList.filter((v) => !!v).length > 0) {
        addStepContent({
          type: "checkbox",
          content: contentList,
          title,
        });
      } else {
        show({
          type: "error",
          message: "Todas informações devem ser preenchidas.",
        });

        setError(true);
      }

      setSelectedStepContent(undefined);
      setSaving(false);
    }
  }, [
    contentList,
    saving,
    title,
    show,
    setError,
    setSaving,
    addStepContent,
    setSelectedStepContent,
  ]);

  useEffect(() => {
    if (stepContent && stepContent.type === "checkbox") {
      setTitle(stepContent.title);
      setContentList(stepContent.content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addNewItem = useCallback(() => {
    if (!newItem) {
      show({
        type: "error",
        message: "O conteúdo precisa ser informado",
      });
      return;
    }

    setContentList((v) => {
      v.push(newItem);
      return v;
    });
    setNewItem("");
  }, [newItem, show]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        addNewItem();
      }
    },
    [addNewItem]
  );

  const deleteRow = useCallback((index: number) => {
    setContentList((v) => v.filter((_, i) => index !== i));
  }, []);

  const handleEditClick = useCallback(
    (index: number) => {
      if (editing !== undefined) {
        const list = [...contentList];
        list[index] = editableValue;

        setContentList(list);
        setEditing(undefined);

        return;
      }

      setEditableValue(contentList[index]);

      setEditing(index);
    },
    [contentList, editableValue, editing]
  );

  return (
    <div>
      <Input
        placeholder="Título"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <div className={styles.addContentContainer}>
        <Input
          placeholder="Conteúdo"
          value={newItem}
          onChange={(e) => setNewItem(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <Button
          onClick={addNewItem}
          className={styles.addButton}
          maxWidth="110px"
        >
          Adicionar
        </Button>
      </div>
      <div className={styles.contentListContainer}>
        {contentList.map((item, index) => (
          <ListItem
            onEdit={() => handleEditClick(index)}
            onDelete={() => deleteRow(index)}
          >
            {editing !== undefined && editing === index ? (
              <input
                value={editableValue}
                onChange={(e) => setEditableValue(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") handleEditClick(index);
                }}
              />
            ) : (
              <>
                <p>{item}</p>
              </>
            )}
          </ListItem>
        ))}
      </div>
    </div>
  );
};

export default Checklist;
