import React, { useCallback } from "react";
import {
  Check,
  EachOperation,
  Icon,
  InputStyled,
  Pencil,
  PositioningArrows,
  Trash,
} from "./styles";
import checkWhite from "~/assets/icons/done-white.svg";
import checkBlack from "~/assets/icons/done-black.svg";
import trashWhite from "~/assets/icons/delete-white.svg";
import trashBlack from "~/assets/icons/delete-black.svg";
import pencilWhite from "~/assets/icons/pencil-white.svg";
import pencilBlack from "~/assets/icons/pencil-black.svg";
import arrowDown from "~/assets/icons/arrow-down-grey.svg";
import usePart from "~/hooks/usePart";

interface OperationItemProps {
  index: number;
  modifying: number | undefined;
  setModifying(index: number | undefined): void;
}

const OperationItem: React.VFC<Operation & OperationItemProps> = ({
  index,
  modifying,
  operationTitle,
  setModifying,
}) => {
  const {
    selected,
    part,
    setSelected,
    removeOperation,
    changeOperation,
    changeOperationPosition,
  } = usePart();

  const handleDelete = useCallback(() => {
    removeOperation(index);
    setSelected(undefined);
    setModifying(undefined);
  }, [index, removeOperation, setModifying, setSelected]);

  const handleChangePosition = useCallback(
    (order: "up" | "down") => {
      changeOperationPosition(index, order);

      setSelected(order === "up" ? index - 1 : index + 1);
    },
    [index, setSelected, changeOperationPosition]
  );

  return (
    <div>
      <EachOperation
        onClick={() => {
          if (selected !== index && !modifying) {
            setSelected(index);
          }
        }}
        selected={selected === index}
      >
        {selected === index && !modifying && (
          <PositioningArrows>
            {index !== 0 && (
              <button type="button" onClick={() => handleChangePosition("up")}>
                <img
                  src={arrowDown}
                  alt="arrow up"
                  style={{ transform: "rotate(180deg)" }}
                />
              </button>
            )}
            {index !== part.operationList.length - 1 && (
              <button
                type="button"
                onClick={() => handleChangePosition("down")}
              >
                <img src={arrowDown} alt="arrow down" />
              </button>
            )}
          </PositioningArrows>
        )}

        {modifying === index ? (
          <InputStyled
            autoFocus
            type="text"
            name="operationTitle"
            onChange={(e) => changeOperation(index, e.target.value)}
            value={operationTitle}
          />
        ) : (
          operationTitle
        )}

        <Icon>
          {modifying === index ? (
            <Check
              src={selected === index ? checkWhite : checkBlack}
              alt=""
              onClick={() => setModifying(undefined)}
            />
          ) : (
            <Pencil
              src={selected === index ? pencilWhite : pencilBlack}
              alt=""
              onClick={() => setModifying(index)}
            />
          )}

          <Trash
            src={selected === index ? trashWhite : trashBlack}
            alt=""
            onClick={handleDelete}
          />
        </Icon>
      </EachOperation>
    </div>
  );
};

export default OperationItem;
