import styled from "styled-components";

interface EachOperationProps {
  selected: boolean;
}

export const EachOperation = styled.li<EachOperationProps>`
  background-color: ${({ selected }) => (selected ? "var(--primary)" : "#eee")};
  color: ${({ selected }) => (selected ? "white" : "var(--primary)")};
  padding: 5px;
  border: 1px solid ${({ selected }) => (selected ? "var(--primary)" : "eee")};
  display: flex;
  flex-direction: row;
  position: relative;
  height: 32px;

  &:hover {
    cursor: pointer;
  }
`;

export const PositioningArrows = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: 8px;

  button {
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button :hover {
    background-color: #129997;
  }

  img {
    width: 15px;
    height: 15px;
  }
`;

export const Pencil = styled.img`
  margin-right: 5px;
  &:hover {
    cursor: pointer;
  }
`;

export const Check = styled.img`
  margin-right: 5px;
  &:hover {
    cursor: pointer;
  }
`;

export const Trash = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

export const InputStyled = styled.input`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.white};
  width: 70%;
  border-bottom: 2px solid ${({ theme }) => theme.colors.white};
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 5px;
`;
