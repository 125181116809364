/* eslint-disable react/prop-types */
import React from "react";
import { PartProvider } from "./usePart";
import { ToastProvider } from "./useToast";

const ApplicationContext: React.FC = ({ children }) => (
  <ToastProvider>
    <PartProvider>{children}</PartProvider>
  </ToastProvider>
);

export default ApplicationContext;
