import { AuthWrapper } from "./styles";

interface AuthLayoutProps {
  children: React.ReactNode;
}

const AuthLayout = ({ children }: AuthLayoutProps) => (
  <AuthWrapper>{children}</AuthWrapper>
);

export default AuthLayout;
