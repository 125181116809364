/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useCallback, useEffect, useState } from "react";
import Input from "~/components/Input";
import { useStyles, ModalStyled } from "./styles";
import pencil from "~/assets/icons/pencil-black.svg";
import done from "~/assets/icons/done-black.svg";
import usePart from "~/hooks/usePart";
import image_black from "~/assets/icons/image-black.svg";
import image_green from "~/assets/icons/image-green.svg";
import useToast from "~/hooks/useToast";
import Button from "~/components/Button";

const ToolTable = () => {
  const styles = useStyles();
  const [title, setTitle] = useState<string>("");
  const [tableHeaders, setTableHeaders] = useState<string[]>([]);
  const [tableRows, setTableRows] = useState<ToolTableRows[][]>([]);
  const [editingCell, setEditingCell] = useState<[number, number]>([-1, -1]);
  const [editingValue, setEditingValue] = useState<string>("");
  const [editingHeader, setEditingHeader] = useState<number>(-1);
  const [editingHeaderValue, setEditingHeaderValue] = useState<string>("");
  const [openImageModal, setOpenImageModal] = useState<boolean>(false);
  const [imageURL, setImageURL] = useState<string>("");
  const [editingImage, setEditingImage] = useState<[number, number]>([-1, -1]);

  const {
    saving,
    stepContent,
    setError,
    setSaving,
    addStepContent,
    setSelectedStepContent,
  } = usePart();

  const { show } = useToast();

  useEffect(() => {
    if (saving) {
      setError(false);
      if (tableHeaders.length > 0 && tableRows.length > 0) {
        addStepContent({
          type: "tool-table",
          content: { columns: tableHeaders, rows: tableRows },
          title,
        });
      } else {
        show({
          type: "error",
          message: "Todas informações devem ser preenchidas.",
        });

        setError(true);
      }

      setSelectedStepContent(undefined);
      setSaving(false);
    }
  }, [
    title,
    saving,
    tableRows,
    tableHeaders,
    show,
    setError,
    setSaving,
    addStepContent,
    setSelectedStepContent,
  ]);

  useEffect(() => {
    if (stepContent && stepContent.type === "tool-table") {
      const { content } = stepContent;

      setTitle(stepContent.title);

      setTableHeaders(content.columns);
      setTableRows(content.rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddHeader = useCallback(() => {
    // Adds one more column to the headers
    setTableHeaders((headers) => [...headers, `Título ${headers.length + 1}`]);

    // Adds one more column to each row
    setTableRows((rows) => {
      const fixedRows = rows.map((row, rowIndex) => {
        row.push({ content: `C${tableHeaders.length + 1} - L${rowIndex + 1}` });

        return row;
      });

      return fixedRows;
    });
  }, [tableHeaders]);

  const handleAddRow = useCallback(() => {
    setTableRows((rows) => {
      // Adds a new row with the correct amount of columns
      const newRow = tableHeaders.map((_, columnIndex) => ({
        content: `C${columnIndex + 1} - L${tableRows.length + 1}`,
      }));

      return [...rows, newRow];
    });
  }, [tableHeaders, tableRows]);

  const confirmRowEdit = useCallback(() => {
    setTableRows((rows) => {
      rows[editingCell[0]][editingCell[1]] = { content: editingValue };

      return rows;
    });
  }, [editingCell, editingValue]);

  const isRowBeingEdited = useCallback(
    (rowIndex: number, columnIndex: number) =>
      rowIndex === editingCell[0] && columnIndex === editingCell[1],
    [editingCell]
  );

  const confirmHeaderEdit = useCallback(() => {
    setTableHeaders((headers) => {
      headers[editingHeader] = editingHeaderValue;

      return headers;
    });
  }, [editingHeader, editingHeaderValue]);

  const isTitleBeingEdited = useCallback(
    (columnIndex: number) => columnIndex === editingHeader,
    [editingHeader]
  );

  const deleteRow = useCallback((rowIndex: number) => {
    setTableRows((rows) => rows.filter((_, i) => i !== rowIndex));
  }, []);

  const deleteColumn = useCallback((columnIndex: number) => {
    setTableHeaders((headers) => headers.filter((_, i) => i !== columnIndex));

    // There's a bug here, add a column and 2 rows, then delete the column
    // and add it again, it creates two rows, because the row.filter() is returning
    // the empty arrays instead of nothing, causing the rows array to be length 2
    // with 2 empty arrays inside
    setTableRows((rows) => {
      // remove the column index of each row content
      const a = rows.map((row) => {
        const b = row.filter((_, cI) => cI !== columnIndex);
        return b;
      });

      return a;
    });
  }, []);

  const closeImageModal = useCallback(() => {
    setImageURL("");
    setOpenImageModal(false);
    setEditingImage([-1, -1]);
  }, [setImageURL, setOpenImageModal, setEditingImage]);

  const saveImageURL = useCallback(() => {
    setTableRows((rows) => {
      rows[editingImage[0]][editingImage[1]] = {
        ...rows[editingImage[0]][editingImage[1]],
        image_url: imageURL,
      };

      return rows;
    });

    closeImageModal();
  }, [closeImageModal, editingImage, imageURL]);

  return (
    <>
      <div className={styles.controlsContainer}>
        <Input
          placeholder="Título"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Button
          size="sm"
          onClick={handleAddHeader}
          className={styles.addButton}
        >
          + Coluna
        </Button>
        {tableHeaders.length > 0 && (
          <Button onClick={handleAddRow} className={styles.addButton}>
            + Linha
          </Button>
        )}
      </div>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            {tableHeaders.map((header, columnIndex) => (
              <th className={styles.tableHeader}>
                <div className={styles.flexCell}>
                  {isTitleBeingEdited(columnIndex) ? (
                    <input
                      className={styles.editInput}
                      value={editingHeaderValue}
                      onChange={(e) => setEditingHeaderValue(e.target.value)}
                    />
                  ) : (
                    <p>{header}</p>
                  )}
                  {isTitleBeingEdited(columnIndex) ? (
                    <img
                      src={done}
                      alt=""
                      onClick={() => {
                        confirmHeaderEdit();
                        setEditingHeader(-1);
                        setEditingHeaderValue("");
                      }}
                    />
                  ) : (
                    <div className={styles.headerButtons}>
                      <img
                        src={pencil}
                        alt=""
                        className={styles.icon}
                        onClick={() => {
                          setEditingHeader(columnIndex);
                          setEditingHeaderValue(header);
                        }}
                      />
                      <button
                        type="button"
                        onClick={() => deleteColumn(columnIndex)}
                        className={styles.deleteColumnButton}
                      >
                        X
                      </button>
                    </div>
                  )}
                </div>
              </th>
            ))}
          </thead>
          <tbody>
            {tableRows.map((row, rowIndex) => {
              return (
                <tr className={styles.tableRow}>
                  {row.map((data, columnIndex) => (
                    <td className={styles.tableData}>
                      {columnIndex === 0 && (
                        <button
                          type="button"
                          onClick={() => deleteRow(rowIndex)}
                          className={styles.deleteRowButton}
                        >
                          X
                        </button>
                      )}
                      <div className={styles.flexCell}>
                        {isRowBeingEdited(rowIndex, columnIndex) ? (
                          <input
                            className={styles.editInput}
                            value={editingValue}
                            onChange={(e) => setEditingValue(e.target.value)}
                          />
                        ) : (
                          <p>{data.content}</p>
                        )}
                        {isRowBeingEdited(rowIndex, columnIndex) ? (
                          <img
                            src={done}
                            alt=""
                            onClick={() => {
                              confirmRowEdit();
                              setEditingCell([-1, -1]);
                              setEditingValue("");
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <div className={styles.cellButtons}>
                            <img
                              src={pencil}
                              alt=""
                              className={styles.icon}
                              onClick={() => {
                                setEditingCell([rowIndex, columnIndex]);
                                setEditingValue(data.content);
                              }}
                            />
                            <img
                              src={data.image_url ? image_green : image_black}
                              alt=""
                              className={styles.icon}
                              onClick={() => {
                                setEditingImage([rowIndex, columnIndex]);
                                setImageURL(data.image_url || "");
                                setOpenImageModal(true);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <ModalStyled
        portalClassName={styles.modalContainer}
        isOpen={openImageModal}
        onRequestClose={closeImageModal}
        style={{
          overlay: { backgroundColor: "rgba(64, 64, 52, 0.43)" },
        }}
      >
        <Input
          value={imageURL}
          onChange={(e) => setImageURL(e.target.value)}
          placeholder="Link da Imagem"
        />
        <button
          type="button"
          onClick={saveImageURL}
          className={styles.addButton}
        >
          Confirmar
        </button>
        <button
          type="button"
          onClick={closeImageModal}
          className={styles.cancelButton}
        >
          Cancelar
        </button>
      </ModalStyled>
    </>
  );
};

export default ToolTable;
