import { useState, useCallback } from "react";
import useStyles from "./styles";
import ActionButton from "~/components/ActionButton";
import bin from "~/assets/icons/bin.svg";
import pencil from "~/assets/icons/pencil-white.svg";
import done from "~/assets/icons/done-white.svg";

interface ListItemProps {
  onEdit: () => void;
  onDelete: () => void;
}

const ListItem: React.FC<ListItemProps> = ({ children, onEdit, onDelete }) => {
  const [edit, setEdit] = useState<boolean>(false);

  const styles = useStyles();

  const handleEdit = useCallback(() => {
    setEdit((v) => !v);
    onEdit();
  }, [onEdit]);

  return (
    <div className={styles.contentList}>
      {children}
      <div className={styles.contentListButtonsContainer}>
        {edit ? (
          <ActionButton
            className={styles.contentListButtons}
            color="var(--primary)"
            icon={done}
            onClick={handleEdit}
          />
        ) : (
          <ActionButton
            className={styles.contentListButtons}
            color="var(--primary)"
            icon={pencil}
            onClick={handleEdit}
          />
        )}
        <ActionButton
          className={styles.contentListButtons}
          color="var(--red)"
          icon={bin}
          onClick={onDelete}
        />
      </div>
    </div>
  );
};

export default ListItem;
