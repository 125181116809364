import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Button from "~/components/Button";
import PartDetails from "~/components/PartDetails";
import usePart from "~/hooks/usePart";
import useToast from "~/hooks/useToast";
import { useStyles, Container } from "./styles";

const Register = () => {
  const styles = useStyles();
  const [named, setNamed] = useState<boolean>(false);

  const { part, edit, clear, create, changePart } = usePart();
  const { show } = useToast();

  const { state } = useLocation<RegisterLocationState>();

  const verifyFields = () => {
    if (part?.SKU && part?.desc) {
      if (state?.editing) {
        edit(part.SKU, part.desc);
      } else {
        create(part.SKU, part.desc);
      }

      setNamed(true);
    } else {
      show({
        type: "error",
        message: "Por favor, preencha todos os campos.",
      });
      setNamed(false);
    }
  };

  useEffect(() => {
    if (!state?.editing) {
      clear();
    }
  }, [state, clear]);

  return (
    <div id="root" className={styles.root}>
      {named ? (
        <PartDetails onSave={() => setNamed(false)} />
      ) : (
        <Container>
          <input
            type="text"
            placeholder="SKU"
            className={styles.input}
            value={part.SKU}
            onChange={(e) => changePart({ SKU: e.target.value })}
          />

          <input
            type="text"
            placeholder="Nome da Peça"
            className={styles.input}
            value={part.desc}
            onChange={(e) => changePart({ desc: e.target.value })}
          />

          <Button size="lg" maxWidth="300px" onClick={verifyFields}>
            Próximo
          </Button>
        </Container>
      )}
    </div>
  );
};

export default Register;
