import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBPb_dAUsgrJYu3voPH_5ijZX0qRCeZ4j4",
  authDomain: "victum-front.firebaseapp.com",
  projectId: "victum-front",
  storageBucket: "victum-front.appspot.com",
  messagingSenderId: "1045333873207",
  appId: "1:1045333873207:web:40031e5f708566112625b2",
};

export const initializeFirebase = () => initializeApp(firebaseConfig);
