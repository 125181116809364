import React from "react";

import { Button as ButtonStyled, ButtonProps } from "./styles";
import Loading from "../Loading";

const Button: React.FC<Partial<ButtonProps>> = ({
  children,
  color = "var(--primary)",
  border = false,
  maxWidth = "200px",
  textColor = "var(--white)",
  size = "lg",
  disabled = false,
  loading = false,
  ...props
}) => {
  return (
    <ButtonStyled
      {...props}
      color={color}
      border={border}
      maxWidth={maxWidth}
      textColor={textColor}
      size={size}
      disabled={disabled || loading}
    >
      {loading ? <Loading /> : <>{children}</>}
    </ButtonStyled>
  );
};

export default Button;
