import { Switch } from "react-router-dom";
import Route from "./Route";
import Login from "~/pages/Login";
import Query from "~/pages/Query";
import Register from "~/pages/Register";
import Logout from "~/pages/Logout";
import GenericNotFound from "~/pages/GenericNotFound";

// import Dashboard from "~/pages/Dashboard";
// <Route path="/dashboard" isPrivate component={Dashboard} />

const Routes = () => (
  <Switch>
    <Route path="/" isPrivate={false} exact component={Login} />
    <Route path="/cadastro" isPrivate component={Register} />
    <Route path="/consulta" isPrivate component={Query} />
    <Route path="/logout" isPrivate component={Logout} />
    <Route component={GenericNotFound} />
  </Switch>
);

export default Routes;
