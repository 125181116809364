import { Wrapper, Container, Content } from "./styles";
import TopBar from "~/components/TopBar";
import Drawer from "~/components/Drawer";

interface DefaultLayoutProps {
  children: React.ReactNode;
}

const DefaultLayout = ({ children }: DefaultLayoutProps) => (
  <Wrapper>
    <TopBar />
    <Container>
      <Drawer />
      <Content>{children}</Content>
    </Container>
  </Wrapper>
);

export default DefaultLayout;
