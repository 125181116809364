import { useCallback, useState } from "react";
import autopflegeLogo from "~/assets/autopflege-logo.png";
import useToast from "~/hooks/useToast";
import Auth from "~/services/Auth";
import history from "~/services/history";
import useStyles from "./styles";

interface LoginForm {
  login: string;
  password: string;
}

const Login = () => {
  const styles = useStyles();
  const [form, setForm] = useState<LoginForm>({ login: "", password: "" });

  const { show } = useToast();

  const handleLogin = useCallback(async (): Promise<void> => {
    if (!form.login) {
      show({
        type: "error",
        message: "O usuário precisa ser preenchido.",
      });
      return;
    }

    if (!form.password) {
      show({
        type: "error",
        message: "A senha precisa ser preenchida.",
      });
      return;
    }

    try {
      const { token } = await Auth.login(form.login, form.password);

      if (token) {
        history.push("/");
        return;
      }
    } catch {
      setForm({ ...form, password: "" });

      show({
        type: "error",
        message: "Usuário ou senha incorreto(s)",
      });
    }
  }, [show, form]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setForm({ ...form, [e.target.name]: e.target.value });

  return (
    <div className={styles.root}>
      <div className={styles.loginContainer}>
        <img className={styles.companyLogo} src={autopflegeLogo} alt="" />
        <form onSubmit={(e) => e.preventDefault()} className={styles.form}>
          <input
            type="text"
            name="login"
            placeholder="Login"
            className={styles.input}
            value={form.login}
            onChange={handleChange}
          />
          <input
            type="password"
            name="password"
            placeholder="Senha"
            className={styles.input}
            value={form.password}
            onChange={handleChange}
          />
          <button type="submit" className={styles.button} onClick={handleLogin}>
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
