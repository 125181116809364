import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  root: {
    height: "70px",
    display: "flex",
    justifyContent: "center",
    flex: 1,
    backgroundColor: "#129997",
  },
  container: {
    width: "98vw",
    marginTop: 10,
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  image: {
    height: 25,
  },
});

export default useStyles;
