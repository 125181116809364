import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  addContentContainer: {
    display: "flex",
  },
  addButton: {
    marginLeft: 10,
    padding: "5px 15px",
    height: "100%",
    alignSelf: "flex-end",
  },
  contentListContainer: {
    overflow: "auto",
    maxHeight: "28vh",
    marginTop: 20,
  },
});

export default useStyles;
