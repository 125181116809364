import { createUseStyles } from "react-jss";
import styled from "styled-components";
import Modal from "react-modal";
import defaultTheme from "~/theme";

export const ModalStyled = styled(Modal)`
  background: ${({ theme: { colors } }) => colors.white};
  border: 2px solid var(--primary);
  border-radius: 4px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const useStyles = createUseStyles({
  modalContainer: {
    "& > div": {
      zIndex: 9999,
    },
  },
  controlsContainer: {
    display: "flex",
  },
  addButton: {
    padding: "5px 10px",
    maxHeight: 30,
    marginLeft: 10,
    width: 130,
  },
  cancelButton: {
    border: "none",
    borderRadius: 50,
    backgroundColor: defaultTheme.colors.red,
    padding: "5px 10px",
    maxHeight: 30,
    marginLeft: 10,
    width: 100,
    color: "white",
  },
  tableContainer: {
    marginTop: 20,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  table: {
    borderSpacing: 0,
  },
  tableHeader: {
    borderBottom: "1px solid black",
    minWidth: 140,
    padding: "3px 5px",
    margin: 0,
  },
  tableRow: {
    backgroundColor: defaultTheme.colors.wildSand,

    "&:nth-child(even)": {
      backgroundColor: defaultTheme.colors.iron,
    },
  },
  tableData: {
    position: "relative",
  },
  cellButtons: {
    display: "flex",
  },
  deleteRowButton: {
    color: defaultTheme.colors.white,
    fontWeight: 600,
    position: "absolute",
    textAlign: "center",
    top: 1,
    left: -22,
    border: "none",
    borderRadius: 50,
    backgroundColor: defaultTheme.colors.red,
    width: 18,
  },
  deleteColumnButton: {
    color: defaultTheme.colors.white,
    fontWeight: 600,
    textAlign: "center",
    border: "none",
    borderRadius: 50,
    backgroundColor: defaultTheme.colors.red,
    width: 18,
    marginLeft: 5,
  },
  headerButtons: {
    display: "flex",
  },
  flexCell: {
    display: "flex",
    justifyContent: "space-between",
    padding: "3px 5px",
  },
  icon: {
    marginLeft: 5,
    cursor: "pointer",
    width: 14,
  },
  editInput: {
    border: "none",
    maxWidth: 100,
  },
});
