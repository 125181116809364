/* eslint-disable react/prop-types */
import { useState, useCallback, useMemo } from "react";
import axios, { AxiosResponse } from "axios";
import { useLocation } from "react-router";
import Table from "~/components/Table";
import ActionButton from "../ActionButton";
import bin from "~/assets/icons/bin.svg";
import pencil from "~/assets/icons/pencil-white.svg";
import { AddNew, useStyles, AddNewStep, Content, SaveButton } from "./styles";
import StepModal from "../StepModal";
import usePart from "~/hooks/usePart";
import OperationItem from "../Operation";
import useToast from "~/hooks/useToast";
import Button from "../Button";

interface PartDetailsProps {
  onSave: () => void;
}

const PartDetails = ({ onSave }: PartDetailsProps) => {
  const styles = useStyles();
  const [modifying, setModifying] = useState<number | undefined>();
  const [stepModalVisible, setStepModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    part,
    selected,
    save,
    change,
    addOperation,
    removeStep,
    setSelectedStep,
  } = usePart();

  const { show } = useToast();
  const { state } = useLocation<RegisterLocationState>();

  const handleEditStepClick = useCallback(
    (index: number) => {
      setSelectedStep(index);
      setStepModalVisible(true);
    },
    [setSelectedStep]
  );

  const COLUMNS: Column<Step>[] = useMemo(
    () => [
      { header: "Título", value: "title" },
      {
        header: "Quantidade de Passos",
        value: ({ stepContent }) => stepContent?.length,
      },
      {
        header: "",
        isComponent: true,
        value: (_, index) => [
          <ActionButton
            color="var(--primary)"
            icon={pencil}
            onClick={() => handleEditStepClick(index)}
          />,
          <ActionButton
            color="var(--red)"
            icon={bin}
            onClick={() => removeStep(index)}
          />,
        ],
      },
    ],
    [handleEditStepClick, removeStep]
  );

  const handleCreateStepClick = useCallback(() => {
    setSelectedStep(undefined);
    setStepModalVisible(true);
  }, [setSelectedStep]);

  const handleSave = useCallback(async () => {
    setLoading(true);
    try {
      if (state?.editing) {
        await change();
      } else {
        await save();
      }

      show({
        type: "success",
        message: "Cadastro realizado com sucesso.",
      });

      onSave();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const response = e.response as AxiosResponse<ErrorResponse>;

        show({
          type: "error",
          message:
            response?.data?.message ||
            "Não foi possível salvar no momento, tente novamente mais tarde.",
        });
        return;
      }

      show({
        type: "error",
        message:
          "Não foi possível salvar no momento, tente novamente mais tarde.",
      });
    } finally {
      setLoading(false);
    }
  }, [state?.editing, save, show, onSave, change]);

  return (
    <>
      <ul className={styles.list}>
        <div>
          {part.operationList.map((item, index) => (
            <OperationItem
              {...item}
              index={index}
              modifying={modifying}
              setModifying={setModifying}
            />
          ))}
        </div>
        <AddNew type="button" onClick={addOperation}>
          + Adicionar Nova Operação para <strong>{part.SKU}</strong>
        </AddNew>
      </ul>

      <Content>
        {selected !== undefined && (
          <>
            <AddNewStep>
              <Button
                onClick={handleCreateStepClick}
                size="lg"
                maxWidth="300px"
              >
                + Adicionar novo passo
              </Button>
            </AddNewStep>

            <Table
              darkMode
              columns={COLUMNS}
              data={part.operationList[selected]?.steps}
              onGetKey={({ title }) => title}
            />
          </>
        )}
      </Content>

      <StepModal
        visible={stepModalVisible}
        onClose={() => setStepModalVisible(false)}
      />

      {part.operationList.length > 0 &&
        part.operationList.some((item) => item.steps.length > 0) && (
          <SaveButton loading={loading} onClick={handleSave}>
            Salvar
          </SaveButton>
        )}
    </>
  );
};

export default PartDetails;
