import User from "./User";
import logo from "~/assets/autopflege.svg";
import useStyles from "./styles";

const TopBar = () => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <img className={styles.image} src={logo} alt="logo" />
        <User />
      </div>
    </div>
  );
};

export default TopBar;
