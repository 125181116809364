// these two eslint rules are for the correct working of the outside click
// and the closing img icon
import React, { useCallback, useEffect, useRef } from "react";
import closeIcon from "~/assets/icons/dark-close.svg";
import {
  OuterDiv,
  CloseButton,
  InnerMessage,
  ButtonContainer,
  MessageInnerDiv,
} from "../styles";
import Button from "~/components/Button";

interface MessageProps {
  isOpen: boolean;
  loading: boolean;
  handleClose: () => void;
  handleCancel: () => void;
  handleConfirm: () => void;
}

const Message: React.FC<MessageProps> = ({
  isOpen,
  loading,
  handleClose,
  handleConfirm,
  handleCancel,
  children,
  ...props
}) => {
  const rootReference = useRef<HTMLDivElement>(null);
  const innerReference = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      // this checks if the click is in the outer div
      if (rootReference?.current?.contains(e.target as Node)) {
        // this checks if the click is in the inner div
        if (innerReference?.current?.contains(e.target as Node)) {
          // so, if click is inside inner div, dont close
          return;
        }
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClick, false);
    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  }, [handleClick]);

  return (
    <OuterDiv {...props} isOpen={isOpen} ref={rootReference}>
      <MessageInnerDiv ref={innerReference}>
        <CloseButton src={closeIcon} alt="Fechar" onClick={handleClose} />
        <InnerMessage>{children}</InnerMessage>
        <ButtonContainer>
          <Button loading={loading} onClick={handleConfirm}>
            Confirmar
          </Button>
          <Button
            disabled={loading}
            onClick={handleCancel}
            color="var(--red)"
            border
          >
            Cancelar
          </Button>
        </ButtonContainer>
      </MessageInnerDiv>
    </OuterDiv>
  );
};

export default Message;
