import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;

  background: #129997;
`;

export const Content = styled.div`
  padding: 10px 20px;
  height: calc(100vh - 71px);
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: auto;
  flex-direction: column;
  background: linear-gradient(#eee, #ddd);

  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  -webkit-box-shadow: 0px 3px 15px -1px #000000;
  box-shadow: 0px 3px 15px -1px #000000;
`;
