/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useRef, useEffect, useCallback } from "react";
import history from "~/services/history";
import useStyles from "./styles";
import Auth from "~/services/Auth";

const User = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const user = Auth.getCurrentUser();
  const optionDiv = useRef<HTMLDivElement>(null);
  const styles = useStyles();

  const handleLogout = useCallback(() => {
    history.push("/logout");
  }, []);

  const handleClick = useCallback((e: MouseEvent) => {
    if (optionDiv?.current?.contains(e.target as Node)) {
      return;
    }

    setDropdownOpen(false);
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick, false);
    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  }, [handleClick]);

  return (
    <div className={styles.root} onClick={() => setDropdownOpen(!dropdownOpen)}>
      <div className={styles.avatar}>{user?.name[0].toUpperCase()}</div>
      <p className={styles.name}>{user?.name}</p>
      <div className={styles.dropdownContainer} ref={optionDiv}>
        {dropdownOpen && (
          <div
            className={styles.dropdownMenu}
            aria-hidden="true"
            onClick={handleLogout}
          >
            <ul>
              <li className={styles.dropdownMenuItem}>Sair</li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default User;
