import { createUseStyles } from "react-jss";
import theme from "~/theme";

export const useStyles = createUseStyles(() => ({
  placeholder: {
    margin: 0,
  },
  inputWrapper: {
    height: 30,
    width: 200,
    borderWidth: 0,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.iron,
    transition: "border-bottom-color 500ms",

    "&:focus": {
      borderBottomColor: theme.colors.osloGray,
    },
  },
}));
