import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Container, Message } from "./styles";

interface ToastConfig {
  type: "success" | "warning" | "error";
  visible: boolean;
  message: string;
  delay?: number;
}

interface ToastProps {
  show: (data: Omit<ToastConfig, "visible">) => void;
}

const ToastContext = createContext({} as ToastProps);

export const ToastProvider: React.FC = ({ children }) => {
  const [options, setOptions] = useState<ToastConfig>({
    type: "error",
    visible: false,
    message: "",
  });

  const show = useCallback((data: Omit<ToastConfig, "visible">) => {
    setTimeout(() => {
      setOptions({
        ...data,
        visible: true,
      });
    }, data.delay || 0);
  }, []);

  useEffect(() => {
    if (options.visible) {
      setTimeout(() => {
        setOptions((e) => ({
          ...e,
          visible: false,
        }));
      }, 3000);
    }
  }, [options.visible]);

  return (
    <ToastContext.Provider value={{ show }}>
      <Container show={options.visible}>
        <Message>{options.message}</Message>
      </Container>
      {children}
    </ToastContext.Provider>
  );
};

const useToast = (): ToastProps => {
  const context = useContext(ToastContext);

  if (context) {
    return context;
  }

  throw new Error("Toast Provider must be setted.");
};

export default useToast;
