import { createUseStyles } from "react-jss";
import styled from "styled-components";

export const useStyles = createUseStyles({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    // border: "2px solid var(--primary)",
  },
  input: {
    border: "none",
    backgroundColor: "rgba(65, 70, 70, 0.2)",
    color: "var(--gray)",
    fontWeight: 600,
    fontSize: 20,
    borderRadius: 50,
    padding: "15px 20px",
    marginBottom: 15,
    minWidth: "300px",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    height: "100%",
    minWidth: "200px",
    backgroundColor: "white",
    // border: "2px solid var(--primary)",
  },
  icons: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    right: 5,
  },
  operationNameInput: {
    border: "none",
    backgroundColor: "transparent",
    color: "white",
    width: "70%",
    borderBottom: "2px solid white",
  },
});

export const AddNew = styled.button`
  border: none;
  background-color: var(--primary);
  color: var(--white);
  font-weight: 300;
  padding: 5px;
  font-size: 15;
  position: absolute;
  bottom: 0;
  left: -5;
  width: 100%;

  &:hover {
    color: black;
  }
`;

export const Pencil = styled.img`
  margin-right: 5px;
  &:hover {
    cursor: pointer;
  }
`;

export const Check = styled.img`
  margin-right: 5px;
  &:hover {
    cursor: pointer;
  }
`;

export const Trash = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

interface EachOperationProps {
  selected: boolean;
}

export const EachOperation = styled.li<EachOperationProps>`
  background-color: ${({ selected }) =>
    selected ? "var(--primary)" : "white"};
  color: ${({ selected }) => (selected ? "white" : "var(--primary)")};
  padding: 5px;
  border: 1px solid ${({ selected }) => (selected ? "var(--primary)" : "white")};
  display: flex;
  flex-direction: row;
  position: relative;
  height: 32px;

  &:hover {
    cursor: pointer;
  }
`;

export const PositioningArrows = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: 8px;

  button {
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 15px;
    height: 15px;
  }
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;
