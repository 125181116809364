import React, { HTMLAttributes } from "react";
import { Container } from "./styles";

interface SelectProps extends HTMLAttributes<HTMLSelectElement> {
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
}

const Select: React.FC<SelectProps> = ({ children, onChange }) => (
  <Container onChange={onChange}>{children}</Container>
);

export default Select;
