import { useEffect, useState } from "react";
import {
  Table,
  TableRow,
  TableData,
  TableHeader,
  TableButtons,
  TableContainer,
  EmptyTableMessage,
} from "./styles";

interface TableComponentProps<T> {
  columns: Column<T>[];
  data: T[];
  darkMode?: boolean;
  onGetKey: (data: T) => string;
  onRowClick?: (data: T) => void;
}

const TableComponent = <T extends unknown>({
  columns,
  data,
  onGetKey,
  onRowClick,
  darkMode = false,
}: TableComponentProps<T>) => {
  const [dataComponent, setDataComponent] = useState<JSX.Element[]>([]);
  const [headerComponent, setHeaderComponent] = useState<
    JSX.Element | JSX.Element[]
  >([]);

  // Generating rows
  useEffect(() => {
    const component = data?.map((row, index) => (
      <TableRow key={onGetKey(row)}>
        {columns?.map(({ value, isComponent }) => {
          if (typeof value === "string") {
            return (
              <TableData
                onClick={() => {
                  if (onRowClick) {
                    onRowClick(row);
                  }
                }}
                key={`${onGetKey(row)}_${Math.random()}}`}
              >
                {(row as { [key: string]: never })[value]}
              </TableData>
            );
          }

          if (typeof value === "function" && isComponent) {
            return (
              <TableButtons key={`${value(row, index)}_${Math.random()}}`}>
                {value(row, index)}
              </TableButtons>
            );
          }

          if (typeof value === "function") {
            return (
              <TableData
                onClick={() => {
                  if (onRowClick) {
                    onRowClick(row);
                  }
                }}
                key={`${value(row, index)}_${Math.random()}}`}
              >
                {value(row, index)}
              </TableData>
            );
          }

          return undefined;
        })}
      </TableRow>
    ));
    setDataComponent(component);
  }, [data, columns, onRowClick, onGetKey]);

  useEffect(() => {
    const headers = (
      <TableRow>
        {columns?.map((column) => (
          <TableHeader key={String(column.value)}>{column.header}</TableHeader>
        ))}
      </TableRow>
    );

    setHeaderComponent(headers);
  }, [columns]);

  return (
    <TableContainer>
      <Table dark={darkMode}>
        {dataComponent && (
          <tbody style={{ position: "relative" }}>{dataComponent}</tbody>
        )}
        <thead>{headerComponent}</thead>
      </Table>
      {dataComponent?.length < 1 && (
        <EmptyTableMessage>Nenhum dado encontrado</EmptyTableMessage>
      )}
    </TableContainer>
  );
};

export default TableComponent;
