// Icons
// import pieChartIcon from "~/assets/icons/chart-pie.svg";
import partPlusIcon from "~/assets/icons/part-plus.svg";
import partSearchIcon from "~/assets/icons/part-search.svg";

interface IDrawerList {
  label: string;
  path: string;
  icon: string;
}

const list: IDrawerList[] = [
  // {
  //   label: "Dashboard",
  //   path: "/dashboard",
  //   icon: pieChartIcon,
  // },
  {
    label: "Cadastro",
    path: "/cadastro",
    icon: partPlusIcon,
  },
  {
    label: "Consulta",
    path: "/consulta",
    icon: partSearchIcon,
  },
];

export default list;
