import styled, { css } from "styled-components";

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  color: string;
  border: boolean;
  maxWidth: string;
  textColor: string;
  size?: "sm" | "lg";
  disabled?: boolean;
  loading?: boolean;
}

export const Button = styled.button<ButtonProps>`
  background-color: ${(props) => props.color};
  max-width: ${(props) => props.maxWidth};
  width: ${(props) => props.maxWidth};
  color: ${(props) => props.textColor};
  margin: 15px;
  white-space: nowrap;
  font-weight: 400;
  text-align: center;
  font-size: 15px;
  padding: 13px 60px;
  border-radius: 50px;
  border: ${(props) =>
    props.border ? `2px solid ${props.textColor}` : "none"};

  &:hover {
    font-weight: ${({ disabled }) => (disabled ? 400 : 600)};
  }

  transition: all 200ms;

  ${({ size }) =>
    size === "sm" &&
    css`
      border: none;
      border-radius: 50px;
      margin-left: 10px;
      padding: 5px 15px;
      height: 100%;
      align-self: flex-end;
      background-color: var(--primary);
      color: ${({ theme }) => theme.colors.white};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.colors.silverSand};
      cursor: not-allowed;
    `}
`;
