/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useCallback } from "react";
import { useLocation } from "react-router";
import history from "~/services/history";
import useStyles from "./styles";
import list from "./DrawerList";
import logout_icon from "~/assets/icons/logout.svg";

const Drawer = () => {
  const styles = useStyles();

  const { pathname } = useLocation();

  const handleLogout = useCallback(() => {
    history.push("/logout");
  }, []);

  return (
    <div className={styles.root}>
      <ul className={styles.listContainer}>
        {list &&
          list.map((item) => (
            <li
              key={item.path}
              className={
                pathname === item.path
                  ? styles.selectedListItem
                  : styles.listItem
              }
              onClick={() => history.push(item.path)}
              aria-hidden="true"
            >
              <img src={item.icon} alt="" className={styles.icon} />
              {item.label}
            </li>
          ))}
        <li onClick={handleLogout} className={styles.lastItem}>
          <img src={logout_icon} alt="" className={styles.logoutIcon} />
          Sair
        </li>
      </ul>
    </div>
  );
};

export default Drawer;
