import { useStyles } from "./styles";

const Input = ({
  placeholder,
  value,
  ref,
  ...props
}: React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>) => {
  const styles = useStyles();

  return (
    <div>
      <p
        className={styles.placeholder}
        style={{ visibility: value ? "visible" : "hidden" }}
      >
        {placeholder}
      </p>
      <input
        ref={ref}
        className={styles.inputWrapper}
        placeholder={placeholder}
        value={value}
        {...props}
      />
    </div>
  );
};

export default Input;
