import styled, { css, keyframes } from "styled-components";

const pulse = keyframes`
0% {
  opacity: 0.3;
}
100% {
  opacity: 1;
}
`;

interface BallProps {
  delay?: string;
}

export const Ball = styled.div<BallProps>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  height: 8px;
  margin: 0 3px;
  width: 8px;

  ${({ delay }) =>
    !!delay &&
    css`
      animation: ${pulse} 3s infinite;
      animation-delay: ${delay};
    `}
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 19px;
`;
