import styled, { css } from "styled-components";

interface ContainerProps {
  show: boolean;
}

export const Container = styled.div<ContainerProps>`
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 10px;
  transition: all 1s ease-in-out;

  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 9999;
  width: 100%;

  ${({ show }) =>
    show &&
    css`
      top: 10px;
    `}

  ${({ show }) =>
    !show &&
    css`
      top: -50px;
    `}
`;

export const Message = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: 5px 5px 15px -4px #000000;
  -webkit-box-shadow: 5px 5px 15px -4px #000000;
  padding: 10px 25px;
  user-select: none;
`;
