import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  root: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  avatar: {
    width: "44px",
    height: "44px",
    backgroundColor: "#0b5e5d",
    borderRadius: 50,
    marginRight: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontWeight: 600,
    fontSize: 17,
  },
  name: {
    fontSize: "18px",
    fontWeight: 600,
    color: "white",
  },
  dropdownContainer: {
    marginTop: 60,
    position: "relative",
  },
  dropdownButton: {
    border: "none",
    background: "none",
  },
  dropdownMenu: {
    position: "absolute",
    right: -10,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    padding: "15px 15px",
    width: 130,
    backgroundColor: "white",
    cursor: "pointer",
  },
  dropdownMenuItem: {
    border: "none",
    background: "none",
    fontSize: 16,
    color: "#474747",
  },
});

export default useStyles;
