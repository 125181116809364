import React, { useCallback } from "react";
import { Container } from "./styles";
import Text from "./components/Text";
import Checklist from "~/components/StepModal/components/Content/components/Checklist";
import Measurement from "./components/Measurement";
import ToolTable from "./components/ToolTable";

interface ContentProps {
  type: StepType;
}

const Content: React.VFC<ContentProps> = ({ type }) => {
  const RenderContent = useCallback(() => {
    switch (type) {
      case "text":
        return <Text />;
      case "checkbox":
        return <Checklist />;
      case "measurement":
        return <Measurement />;
      case "tool-table":
        return <ToolTable />;
      default:
        return null;
    }
  }, [type]);

  return (
    <Container>
      <RenderContent />
    </Container>
  );
};

export default Content;
