import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  contentListContainer: {
    overflow: "auto",
    maxHeight: "28vh",
    marginTop: 20,
  },
  contentList: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid var(--gray)",
    padding: 10,
    width: "100%",
  },
  contentListButtonsContainer: {
    display: "flex",
  },
  contentListButtons: {
    marginLeft: 10,
  },
});

export default useStyles;
