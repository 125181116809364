import { StyledButton, StyledActionButtonProps } from "./styles";

interface ActionButtonProps extends StyledActionButtonProps {
  onClick: () => void;
  icon: string;
}

const ActionButton = ({
  onClick,
  color,
  icon,
  className,
}: ActionButtonProps) => {
  return (
    <StyledButton
      color={color}
      onClick={onClick}
      type="button"
      data-testid="action-button"
      className={className}
    >
      <img src={icon} alt="" />
    </StyledButton>
  );
};

export default ActionButton;
