import { createUseStyles } from "react-jss";
import styled from "styled-components";
import ActionButton from "../Button";

export const useStyles = createUseStyles({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    border: "2px solid var(--primary)",
    backgroundColor: "blue",
  },
  input: {
    border: "none",
    backgroundColor: "rgba(65, 70, 70, 0.2)",
    color: "var(--gray)",
    fontWeight: 600,
    fontSize: 20,
    borderRadius: 50,
    padding: "15px 20px",
    marginBottom: 15,
    minWidth: "300px",
  },
  button: {
    border: "none",
    backgroundColor: "var(--primary)",
    color: "var(--white)",
    fontWeight: 500,
    padding: 10,
    fontSize: 20,
    borderRadius: 50,
    minWidth: "300px",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    height: "100%",
    minWidth: "200px",
    backgroundColor: "#eee",
    border: "2px solid var(--primary)",
    borderRadius: 3,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: 10,
  },
  addButton: {
    width: 300,
  },
});

export const Notification = styled.div`
  background-color: rgba(230, 175, 46, 0.7);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  position: absolute;
  top: 0;
  font-weight: 600;
  font-size: 15;

  &:hover {
    box-shadow: 0px 1px 0px 0.5px rgba(0, 0, 0, 0.2);
  }
`;

export const NotificationButton = styled.button`
  border: none;
  margin: 0;
  padding: 8px;
  background-color: transparent;
  position: absolute;
  right: 20px;
  font-weight: 600;
  font-size: 15;

  &:hover {
    color: red;
  }
`;

export const AddNew = styled.button`
  border: none;
  background-color: var(--primary);
  color: var(--white);
  font-weight: 300;
  padding: 5px;
  font-size: 15;
  position: absolute;
  bottom: 0;
  left: -5;
  width: 100%;

  &:hover {
    font-weight: 500;
  }
`;

export const AddNewStep = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  padding-top: 15px;
  width: 100%;
  margin-left: 10px;
`;

export const SaveButton = styled(ActionButton)`
  bottom: 25px;
  position: absolute;
  right: 25px;
`;
